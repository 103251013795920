import './vendor/gsap.min.js'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './tictoc/ui.js'
import './muirhall/cookie_notice.js.coffee'
import './muirhall/menu.js.coffee'
import './muirhall/project_map.js.coffee'
import './muirhall/location_map.js.coffee'
import './muirhall/video.js.coffee'
import './muirhall/custom_select.js.coffee'
import './muirhall/custom_radio.js.coffee'
import './muirhall/custom_checkbox.js.coffee'
import './muirhall/custom_file.js.coffee'
import './muirhall/article.js.coffee'
import './muirhall/form_show_hide.js.coffee'
import './muirhall/props_bg.js.coffee'
import './muirhall/gallery.js.coffee'
import './muirhall/accordion.js.coffee'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  muirhall.cookie_notice.setup();
  muirhall.menu.setup();
  muirhall.project_map.setup();
  muirhall.location_map.setup();
  muirhall.video.setup();
  muirhall.custom_select.setup();
  muirhall.custom_radio.setup();
  muirhall.custom_checkbox.setup();
  muirhall.custom_file.setup();
  muirhall.article.setup();
  muirhall.form_show_hide.setup();
  muirhall.props_bg.setup();
  muirhall.gallery.setup();
  muirhall.accordion.setup();
});