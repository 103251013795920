window.muirhall ||= {}

muirhall.project_map =
  api_key: "AIzaSyBgA6LpfwJz346wrX31okLSJA-12AjACCk"
  map_elements: document.querySelectorAll('.project_map_element')
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#acadaf"
        },
        {
          "lightness": 50
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4a5050"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#eeefef"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#acadaf"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": -10
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeefef"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4a5050"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeefef"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#acadaf"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#eeefef"
        },
        {
          "lightness": -5
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#acadaf"
        },
        {
          "lightness": 10
        },
        {
          "weight": 0.5
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4a5050"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4a5050"
        },
        {
          "lightness": 50
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#acadaf"
        },
        {
          "lightness": -5
        }
      ]
    },
    {
      "featureType": "transit.station.airport",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit.station.bus",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit.station.rail",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeefef"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4a5050"
        },
        {
          "lightness": 30
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]

  setup: ->
    if muirhall.project_map.map_elements.length == 0
      return false

    !((d, s, id)->
      fjs = d.getElementsByTagName(s)[0]
      if !d.getElementById(id)
        js = d.createElement(s)
        js.id = id
        js.src = "https://maps.googleapis.com/maps/api/js?key=#{muirhall.project_map.api_key}&callback=muirhall.project_map.initialize"
        fjs.parentNode.insertBefore(js,fjs)
      else
        muirhall.project_map.initialize()
    )(document,"script","gmap_loader")

  initialize: ->
    @setup_maps()

  setup_maps: ->
    muirhall.project_map.map_elements.forEach (location, idx) ->
      pin = window.btoa(location.getAttribute('data-icon'))
      map = muirhall.project_map.create_map(location)
      bounds = new google.maps.LatLngBounds()
      markers = []

      document.querySelectorAll('.project_stub_item').forEach (stub, idx) ->
        return unless stub.getAttribute('data-lat') && stub.getAttribute('data-lng')

        marker = new google.maps.Marker({
          position: { lat: parseFloat(stub.getAttribute('data-lat')), lng: parseFloat(stub.getAttribute('data-lng')) },
          icon:
            url: "data:image/svg+xml;base64,#{pin}",
            anchor: new google.maps.Point(17, 21)
          map: map
        })

        stub.querySelector('.project_stub_close_link a').addEventListener 'click', (e) ->
          e.preventDefault()
          stub.style.display = 'none'
          document.querySelector('body').classList.remove('show_project')

        marker.addListener 'click', ->
          document.querySelectorAll('.project_stub_item').forEach (stub) ->
            stub.style.display = 'none'

          stub.style.display = 'block'

          document.querySelector('body').classList.add('show_project')

        bounds.extend(marker.getPosition())
        markers.push(marker)

      if markers.length > 1
        if window.matchMedia('(max-width: 849px)').matches
          boundsOptions = {
            top: 84,
            right: 0,
            bottom: 80,
            left: 0
          }
        else
          boundsOptions = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 202
          }

        map.fitBounds(bounds, boundsOptions)
      else if markers.length == 1
        mapCenter = new google.maps.LatLng(markers[0].position.lat(), markers[0].position.lng())
        map.setCenter(mapCenter)
        map.setZoom(8)

  create_map: (location) ->
    options =
      center: new google.maps.LatLng(location.getAttribute('data-lat'), location.getAttribute('data-lng'))
      mapTypeId: google.maps.MapTypeId.ROADMAP
      maxZoom: 18
      zoomControl: false
      disableDefaultUI: true
      styles: muirhall.project_map.styles

    if window.matchMedia('(max-width: 849px)').matches
      options['zoomControl'] = false
    else
      options['zoomControl'] = true

    new google.maps.Map(location, options)
