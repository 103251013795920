window.muirhall ||= {}

muirhall.props_bg =

  setup: ->
    @setup_props_bg()
  
  setup_props_bg: ->
    props_bg = document.querySelector('.article_list:not(.hidden) .props_bg')
    return unless props_bg

    setTimeout ->
      props_bg_wrapper = document.querySelector('.props_bg_wrapper')
      props_bg_wrapper_height = props_bg_wrapper.offsetHeight
      props_bg.style.height = "#{props_bg_wrapper_height}px"
    , 200