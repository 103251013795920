class Gallery
  constructor: (gallery) ->
    @gallery = gallery
    @gallery_items = gallery.querySelectorAll('.gallery_image_content_block')
    @gallery_next_link = gallery.querySelector('.gallery_block_next_link')
    @gallery_previous_link = gallery.querySelector('.gallery_block_previous_link')

    @setup_initial_values()
    @add_listeners()
  
  setup_initial_values: ->
    @gallery_items[0].setAttribute('data-current-item', true)
    @gallery_items[0].classList.add('current')

  add_listeners: ->
    if @gallery_next_link
      @gallery_next_link.addEventListener 'click', (e) =>
        e.preventDefault()

        current_item_index = @return_current_item()
        @change_item(current_item_index, current_item_index + 1)

    if @gallery_previous_link
      @gallery_previous_link.addEventListener 'click', (e) =>
        e.preventDefault()

        current_item_index = @return_current_item()
        @change_item(current_item_index, current_item_index - 1)

  change_item: (@current_item_index, @new_item_index) ->

    @gallery_items.forEach (item) =>
      item.removeAttribute('data-current-item')
      item.classList.remove('current')

    if @new_item_index < 0
      @new_item_index = @gallery_items.length - 1
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')

    else if @new_item_index >= @gallery_items.length
      @new_item_index = 0
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')


    else
      if @new_item_index > @current_item_index
        @gallery_items[@new_item_index].setAttribute('data-current-item', true)
        @gallery_items[@new_item_index].classList.add('current')

      else
        @gallery_items[@new_item_index].setAttribute('data-current-item', true)
        @gallery_items[@new_item_index].classList.add('current')

    @update_count()

  update_count: ->
    @gallery.querySelector('.gallery_block_current_block').textContent = @new_item_index + 1
  
  return_current_item: ->
    return Array.from(@gallery_items).findIndex((item) -> item.hasAttribute('data-current-item'))

window.muirhall ||= {}

muirhall.gallery =
  setup: ->
    document.querySelectorAll('.gallery_block_content_block').forEach (gallery) ->
      gallery = new Gallery(gallery)