window.muirhall ||= {}

muirhall.article =

  setup: ->
    @setup_article_filter()
    @setup_article_bg()
  
  setup_article_filter: ->
    article_filters = document.querySelectorAll('.tag_filter select')
    return unless article_filters.length

    article_filters.forEach (filter) ->
      filter.addEventListener 'change', (e) ->
        e.target.closest('form').submit()
  
  setup_article_bg: ->
    article_bg = document.querySelector('.article_list_bg')
    return unless article_bg

    setTimeout ->
      article_list = document.querySelector('.article_list')
      article_list_height = article_list.offsetHeight
      article_bg.style.height = "#{article_list_height}px"
    , 200